export const userTypes = {
    FacilityOwner: "facility owner",
    Super: "super",
    Admin: "admin",
    DecisionMaker: "decision maker",
    ContentCreator: "content creator",
    DataCollector: "datacollector",
    DataVerifier: "data verifier",
    FormFillerSuperVisor: "form filler supervisor",
    WaterUser: "water user",
    AirUser: "air user",
    WasteUser: "waste user",
    SpecializedDecisionMaker: "specialized decision maker",
    AgricultureUser: "agriculture user",
};