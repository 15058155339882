export default {
  "أول أكسيد الكربون": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carbon Monoxide (CO)"])},
  "أكاسيد النيتروجين": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nitrogen Oxides (NOx)"])},
  "الجسيمات العالقة (PM10)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Particulate Matter (PM10)"])},
  "المركبات العضوية المتطايرة": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volatile Organic Compounds (VOCs)"])},
  "أكاسيد الكبريت": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sulfur Oxides (SOx)"])},
  "نيتروجين": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nitrogen (N)"])},
  "أمونيا": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ammonia (NH3)"])},
  "المواد الصلبة أقل من 2.5 مم": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Particulate Matter (PM2.5)"])},
  "ميثان": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Methane (CH4)"])},
  "N2O": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nitrous Oxide (N2O)"])},
  "ثاني اوكسيد الكربون": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carbon Dioxide (CO2)"])},
  "أمونيا ناتجه من الماشية": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ammonia from Livestock (NH3)"])},
  "أمونيا ناتجة من الدواجن": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ammonia from Poultry (NH3)"])},
  "PAH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Polycyclic Aromatic Hydrocarbons (PAH)"])},
  "Ni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nickel (Ni)"])},
  "Cr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chromium (Cr)"])},
  "Lead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lead (Pb)"])},
  "Fluorine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fluorine (F)"])},
  "HCL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hydrogen Chloride (HCl)"])},
  "TOCs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Organic Carbon (TOC)"])},
  "H2S": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hydrogen Sulfide (H2S)"])},
  "THC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Hydrocarbons (THC)"])},
  "caprolactam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Caprolactam"])},
  "HTM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heat Transfer Medium (HTM)"])},
  "Mercury": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mercury (Hg)"])},
  "Cadmium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cadmium (Cd)"])},
  "Benzene": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benzene (C6H6)"])},
  "BSO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Biogenic Secondary Organics (BSO)"])},
  "Total Florides": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Fluorides (F−)"])},
  "Benzo-a-pyrene": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benzo[a]pyrene (BaP)"])},
  "Tar acids": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tar Acids"])},
  "Tar bases": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tar Bases"])},
  "Tar oil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tar Oil"])},
  "Tar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tar"])},
  "Cobalt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cobalt (Co)"])},
  "Soot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soot"])},
  "الجسيمات الصلبة الكلية": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Suspended Particles (TSP)"])},
  "يرجى اختيار ملوث": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please Choose a Pollutant"])},
  "المواد الصلبة الذائبة": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dissolved Solids"])},
  "المواد الصلبة العالقة": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suspended Solids"])},
  " أكسجين كيميائي مستهلك": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chemical Oxygen Demand"])},
  "أكسجين حيوي ممتص": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Biochemical Oxygen Demand"])},
  "الفسفور الكلي": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Phosphorus"])},
  "الكبريتات": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sulfates"])},
  "النترات": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nitrates"])},
  "النيتروجين الكلي": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Nitrogen"])},
  "درجة الحرارة": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temperature"])},
  "معادن ثقيلة": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heavy Metals"])},
  "عد البكتريا القولونية CFU/day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coliform Bacteria Count (CFU/day)"])},
  "زيوت وشحوم": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oils and Grease"])},
  "pH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pH"])},
  "Pb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lead"])},
  "Alkalinity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alkalinity"])},
  "Al": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aluminum"])},
  "Iron": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iron"])},
  "K": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potassium"])},
  "AOX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AOX"])},
  "TOC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Organic Carbon"])},
  "urea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Urea"])},
  "Fluoride": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fluoride"])},
  "Phosphate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phosphate"])},
  "TS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Solids"])},
  "Chromium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chromium"])},
  "chlorides": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chlorides"])},
  "sodium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sodium"])},
  "Magnesium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Magnesium"])},
  "calcium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calcium"])},
  "boron": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Boron"])},
  "zinc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zinc"])},
  "silicon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Silicon"])},
  "Nickel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nickel"])},
  "copper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copper"])},
  "Chromium, hexavalent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hexavalent Chromium"])},
  "cobalt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cobalt"])},
  "Tin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tin"])},
  "cadmium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cadmium"])},
  "Antimony": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antimony"])},
  "Barium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Barium"])},
  "Phenol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phenol"])},
  "Ag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Silver"])},
  "Ce": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cerium"])},
  "Nitrogen ammonia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ammonia Nitrogen"])},
  "Cyanide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cyanide"])},
  "TKN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Kjeldahl Nitrogen"])},
  "Formaldehyde": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formaldehyde"])},
  "N-Kj": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nitrogen Kjeldahl"])},
  "furfural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Furfural"])},
  "NMP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N-Methyl-2-pyrrolidone"])},
  "MEK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Methyl Ethyl Ketone"])},
  "البنزين (C6H6)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benzene (C6H6)"])},
  "dissolved HCs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dissolved Hydrocarbons"])},
  "potassium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potassium"])},
  "po4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phosphate (PO4)"])},
  "sulfide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sulfide"])},
  "chlorine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chlorine"])}
}