// import * as Vue from "vue";
// import vuexI18n from "vuex-i18n";
// import * as Vuex from "vuex";
import { createStore } from "vuex";
import VuexPersist from "vuex-persist";
import { formOptions } from "./stores/form-options";
// import createPersistedState from "vuex-persistedstate";

// Vue.use(Vuex);
const vuexPersist = new VuexPersist({
  key: "t6esdfhhkasdf", // unique key for saving state properties in the browser storage
  storage: window.localStorage, // select the type of storage used (eg - localStorage, sessionStorage, etc)
  // reducer: (state) => ({ name: state.name, age: state.age }) // select the state properties that you want persisted
});

export const store = createStore({
  plugins: [vuexPersist.plugin],
  state: () => ({
    formOptions: formOptions,
    user: {
      name: null,
      id: null,
      type: null,
    },
    isLoggedIn: false,
    windowWidth: window.innerWidth,
  }),
  getters: {
    HomePageRouteName(state) {
      let type = state.user.type;
      //console.log("ELTYPE",type)
      switch (type) {
        case "water user":
          return "WaterUser";
        case "air user":
            return "AirUser";
        case "facility owner":
          return "FacilityUserHome";
        case "admin":
          return "AdminCardsHome";
        case "super":
        case "decision maker":
        case "content creator":
          return "AdminHome";
        case "datacollector":
          return "RepresentativeHome";
        case "data verifier":
          return "DataVerifierHome";
        case "form filler supervisor":
          return "FormFillerSupervisorHome";
        case "waste user":
          return "SolidWasteUserDashboard";
        case "specialized decision maker":
          return "SpecializedDecisionMaker";
        case "agriculture user":
          return "AgricultureUserDashboard";
        default:
          return "LandingPage";
      }
    },
    IsUserTypeAllowedToRoute: (state) => (allowedUsers) => {
      return allowedUsers.some((u) => u === state.user.type);
    },
  },
  mutations: {
    setUserName(state, name) {
      state.user.name = name;
    },
    setUserId(state, id) {
      state.user.id = id;
    },
    setUserType(state, type) {
      state.user.type = type;
    },
    setIsLoggedIn(state, isLoggedIn) {
      state.isLoggedIn = isLoggedIn;
    },
    setWindowWidth(state, width) {
      state.windowWidth = width;
    },
  },
  actions: {
    login({ commit }, user) {
      commit("setUserName", user.name);
      commit("setUserId", user.id);
      commit("setUserType", user.role);
      commit("setIsLoggedIn", true);
    },
    logout({ commit }) {
      commit("setUserName", null);
      commit("setUserId", null);
      commit("setUserType", null);
      commit("setIsLoggedIn", false);
    },
  },
});

// Vue.use(vuexI18n.plugin, store);
