export function initDataCollection () {
  return  {
        labels: [],
        datasets: [
          {
            label: "",
            backgroundColor: "#f87979",
            data: [],
          },
        ],
      };
}

export function initDataOptions (x,y,handler) {
  return {
        //ticks: {mirror: true}
        legend: { display: false },
        scales: {
          x: {
            //barThickness: 73,
            //ticks: { fontSize: 20, mirror: false },
            title: {
              display: true,
              text: x,
              //fontSize: 20
            },
          },
          y: {
            //ticks: { fontSize: 20 },
            title: {
              display: true,
              text:y,
              //fontSize: 20
            },
          },
        }, 
        onClick: handler,
      }
}

export function printImages(title, subtitle, images , headerEpap, year,department,city,pollutant) {
      //console.log("shared called");
      var popup = window.open();
      popup.document.write('<div style = "float:right;">');
      popup.document.write(
        '<img src="' +
          process.env.BASE_URL +
          'images/footer.png" style = "margin-right:20%;margin-top:25%" width="90" height="70"/>'
      );
      popup.document.write("</div>");
      popup.document.write('<div style = "float:left;">');
      popup.document.write(
        '<img src="https://i.postimg.cc/s2bhBdyV/ministry.jpg" style = "margin-left:20%;margin-top:8%;margin-bottom:10%" width="50" height="50"/><h3> ' +
          headerEpap +
          " </h3>"
      );
      popup.document.write("</div>");
      popup.document.write(
        '<h1 align="center" style="color: #5e9ca0;">' + title
      );
      popup.document.write("</h1></br>");
      popup.document.write(
        '<h2 align="center" style="color: #5e9ca0;">' + subtitle
      );
      popup.document.write("</h2></br>");
      popup.document.write("</br>");
      popup.document.write("<div style = 'float: right; font-size: 15px;'>");
      if (year != null) {
        popup.document.write("السنة  :" + year);
        popup.document.write("&nbsp &nbsp &nbsp &nbsp");
      }
      if (department != null) {
        popup.document.write("  مركز/قسم:" + department.Department);
        popup.document.write("&nbsp &nbsp &nbsp &nbsp");
      }
      if (city != null) {
        popup.document.write("  المحافظة:" + city.name);
        popup.document.write("&nbsp &nbsp &nbsp &nbsp");
      }
      if (pollutant != null) {
        popup.document.write("الملوث  :" + pollutant.name);
        popup.document.write("&nbsp &nbsp &nbsp &nbsp");
      }
      popup.document.write("</div>");
      popup.document.write("<br/>");
      popup.document.write("<br/>");
      var arrayLength = images.length;
      for (var i = 0; i < arrayLength; i++) {
        popup.document.write('<img width="100%" src=' + images[i] + "><br/>");
      }
      popup.document.close();
      popup.focus();
      setTimeout(function () {
        popup.print();
        popup.close();
      }, 250);
}