const ImportPage = () => import("../components/Import.vue");
const SolidWasteMain = () => import("../components/solid waste user/SolidWasteMain.vue");
const SolidWasteUser = () => import("../components/solid waste user/SolidWasteUser.vue");
const SolidWaste = () => import("../components/solid waste user/SolidWaste.vue");
const AgricultureFactoriesPerSector = () => import("../components/reports/AgricultureFactoriesPerSector.vue")
const MapView = () => import("../components/map/MapView.vue")
const TopFivePollutantPerSector = () => import("../components/reports/TopFivePollutantPerSector.vue")

import { userTypes } from "./userTypes";

export default [
    {
        name: "SolidWasteUser",
        path: "/SolidWasteUser",
        meta: {
            requiresAuth: true,
            allowedUsers: [
                userTypes.Super,
                userTypes.Admin,
                userTypes.ContentCreator,
                userTypes.DecisionMaker,
                userTypes.WasteUser,
            ],
        },
        component: SolidWasteMain,
        children: [
            {
                name: "SolidWasteUserDashboard",
                path: "dashboard",
                meta: {
                    requiresAuth: true,
                    allowedUsers: [
                        userTypes.Super,
                        userTypes.DecisionMaker,
                        userTypes.DataVerifier,
                        userTypes.WasteUser,
                    ],
                },
                component: SolidWasteUser,
            },
            {
                name: "SolidWaste",
                path: "",
                meta: {
                    requiresAuth: true,
                    allowedUsers: [
                        userTypes.Super,
                        userTypes.Admin,
                        userTypes.ContentCreator,
                        userTypes.DecisionMaker,
                        userTypes.WasteUser,
                    ],
                },
                component: SolidWaste,
                children: [
                    {
                        name: "SolidWaste_top-five-pollution-per-sector",
                        path: "reports/top-five-pollution-per-sector",
                        meta: {
                            requiresAuth: true,
                            allowedUsers: [
                                userTypes.Super,
                                userTypes.DecisionMaker,
                                userTypes.DataVerifier,
                                userTypes.WasteUser,
                            ],
                        },
                        component: TopFivePollutantPerSector,
                        props: { name: "Top Five Pollution Per Dump sites Sector", superId: 4, hideHeader: true },
                    },
                    {
                        name: "SolidWaste_ImportFile",
                        path: "import/importFile",
                        component: ImportPage,
                        meta: {
                            requiresAuth: true,
                            allowedUsers: [
                                userTypes.Super,
                                userTypes.Admin,
                                userTypes.WasteUser,
                                userTypes.WasteUser,
                            ],
                        },
                        props: { superId: 4, template: "SolidWasteData.xlsx" },
                    },
                    {
                        name: "SolidWaste_ImportSectorFile",
                        path: "import/SectorFile",
                        component: ImportPage,
                        meta: {
                            requiresAuth: true,
                            allowedUsers: [
                                userTypes.Super,
                                userTypes.Admin,
                                userTypes.WasteUser,
                                userTypes.WasteUser,
                            ],
                        },
                        props: { type: "solidWasteSectorFile", superId: 4, template: "SolidWasteSectors.xlsx" },
                    },
                    {
                        name: "SolidWaste_ImportLookup",
                        path: "import/Lookup",
                        component: ImportPage,
                        meta: {
                            requiresAuth: true,
                            allowedUsers: [
                                userTypes.Super,
                                userTypes.Admin,
                                userTypes.WasteUser,
                            ],
                        },
                        props: { type: "solidWasteFileLookup", superId: 4, template: "SolidWasteLookupTables.xlsx" },
                    },
                    {
                        name: "SolidWaste_MapAllOptions",
                        path: "map/solidWaste",
                        meta: {
                            requiresAuth: true,
                            allowedUsers: [
                                userTypes.Super,
                                userTypes.DecisionMaker,
                                userTypes.DataCollector,
                                userTypes.DataVerifier,
                                userTypes.WaterUser,
                                userTypes.SpecializedDecisionMaker,
                                userTypes.WasteUser,
                            ],
                        },
                        component: MapView,
                        props: { superSectorName: 'Sewage station' },
                    },
                    {
                        name: "SolidWaste_factories-per-sector",
                        path: "reports/factories-per-sector/Factories%20Per%20Agricultural%20Sector/4",
                        meta: {
                            requiresAuth: true,
                            allowedUsers: [
                                userTypes.Super,
                                userTypes.Admin,
                                userTypes.DecisionMaker,
                                userTypes.DataVerifier,
                                userTypes.WasteUser
                            ],
                        },
                        component: AgricultureFactoriesPerSector,
                        props: true,
                    },
                ],
            },
        ],
    }
]