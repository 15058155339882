<template>
  <section class="hero">
    <nav class="breadcrumb" aria-label="breadcrumbs" style="margin-bottom: auto; margin-inline-start: 1em;">
      <ul>
        <li> <router-link :to="{ name: 'AdminHome' }">{{ $t("Home") }}</router-link></li>
        <li> <router-link :to="{ name: 'ReportsTransition' }">{{ $t("Reports") }}</router-link></li>
        <li class="is-active"> <a href="#" aria-current="page">{{ $t(this.name) }}</a> </li>
      </ul>
    </nav>
    <!-- <div class="hero-body is-pale-background">
        <div class="container">
          <h4 class="title" style = "padding-bottom: 10px; font-size: 2.5rem;color:white;">{{$t(this.name)}}</h4>
          <h2 class="subtitle" style = "font-weight: normal; font-size: 21px; color:white;">
              {{$t("This report shows number of pollution sources per government" )}}
          </h2>
        </div>
      </div> -->
  </section>
  <hr style="width:30%;border-width: medium;border-style: groove;margin: 0.5em 0;" />
  <div>
    <div class="small-section">
      <div class="columns">
        <div class="column is-one-third">
          <div class="box has-light-shadow-border" style="margin-top: 0">
            <pollutant-select v-model="pollutant" @input="clearData" zero :horiz="true" :superId="superId"
              :required="true"></pollutant-select>
          </div>
          <div class="box has-light-shadow-border">
            <nfr-select superSectorId="1" v-model="nfrId" zero :horiz="true" :required="true"></nfr-select>
          </div>
          <div class="box has-light-shadow-border">
            <div class="field is-vertical">
              <years-select v-model="year" :from="new Date().getFullYear() - 100" :label="true"
                @input="clearData"></years-select>
            </div>
          </div>

          <div class="field is-grouped" style="margin-inline-start: 1em">
            <div class="control alignmentFix">
              <button :class="[
                { 'is-loading': loading },
                'button',
                'is-medium',
                'is-focused',
                'is-info',
              ]" v-shortkey.once="['enter']" @shortkey="loadData()" @click="loadData">
                {{ $t("Refresh") }}
              </button>
            </div>
            <div class="control">
              <button :class="['button', 'is-medium', 'is-focused', 'is-info']" @click="print">
                {{ $t("Print") }}
              </button>
            </div>
          </div>
        </div>

        <div class="column">
          <nav class="tabs is-boxed option-headers" style="margin-bottom: 0">
            <ul>
              <li class="is-active">
                <a>{{ $t("Main Results") }}</a>
              </li>
            </ul>
          </nav>
          <div style="margin: 5px" class="tile Chart" v-show="headerSelector == 0">
            <div class="card tile is-child">
              <div v-if="dataActive" class="card-content">
                <chart style="background-color: white; width: 100%" ref="chart_1" id="chart_1" :extra="extra"
                  :type="type" :data="dataCollection" :bar_options="dataCollectionOptions"></chart>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import randomColor from "randomcolor";
import { defineAsyncComponent } from "vue";
const PollutantSelect = defineAsyncComponent(() =>
  import("../controls/PollutantSelect.vue")
);
const NfrSelect = defineAsyncComponent(() => import("../controls/NfrSelect.vue"));

const Chart = defineAsyncComponent(() => import("../controls/Chart"));
// const sharedUtilities = () => import('../sharedUtilities')
const YearsSelect = defineAsyncComponent(() =>
  import("../controls/YearsSelect")
);
import { useToast } from "vue-toastification";
import map from "lodash/map";
import range from "lodash/range";

import {
  initDataCollection,
  initDataOptions,
  printImages,
} from "../reports/reportUtil.js";

export default {
  setup() {
    const toast = useToast();
    return { toast }; // Make it available inside methods
  },
  props: {
    name: { type: String, default: "Pollution Per Crop" },
    superId: null,
  },
  data() {
    return {
      extra: "%",
      pollutant: null,
      nfrId: null,
      pollutantCategory: null,
      city: null,
      CityId: -1,
      department: null,
      values: [],
      percentages: [],
      viewby: false,
      year: new Date().getYear() + 1900,
      type: "bar",
      unit: "",
      All_data: [],
      sectorIds: [],
      dataActive: null,
      governmentIds: [],
      dataCollection: initDataCollection(),
      dataCollectionOptions: initDataOptions(
        this.$t("Sub Sectors"),
        this.$t("ton/year"),
        this.clickHandler
      ),
      loading: false,
      headerSelector: 0,
    };
  },
  computed: {
    years() {
      let currentYear = new Date().getYear() + 1900;
      let years = map(range(currentYear, currentYear - 5, -1), (year) => ({
        year: year,
        display: year,
      }));
      //years.unshift({ year: -1, display: "All" });
      return years;
    },
  },
  watch: {
    pollutant: function () {
      if (this.pollutant != null) {
        //console.log("FE EH",this.pollutant.category);
        this.pollutantCategory = this.pollutant.category;
      } else {
        this.pollutantCategory = "Air";
      }
    },
    city: function (newcity, oldcity) {
      if (this.city != null) {
        if (oldcity != null && oldcity.id != newcity.id) {
          this.department = null;
        }
        this.CityId = this.city.id;
      } else {
        this.CityId = -1;
        this.department = null;
      }
    },
    superId: function () {
      this.loadData();
    },
    viewby: function () {
      //console.log(this.viewby);
      this.changeDataset();
    },
  },
  methods: {
    clickHandler(evt, element) {
      if (element[0] != undefined) {
        this.loading = true;
        this.loadData(1);
      }
    },
    clearData() {
      this.dataCollection = initDataCollection();
      this.headerSelector = 0;
    },

    print() {
      var images = [];
      if (this.$refs.chart_2 == undefined) {
        //console.log(this.$refs.chart_1)
        images = [this.$refs.chart_1.print()];
      } else {
        images = [this.$refs.chart_1.print(), this.$refs.chart_2.print()];
      }
      printImages(
        this.$t("Test"),
        this.pollutant.name,
        images,
        this.$t("Egyptian Environmental Affairs Agency"),
        this.year,
        this.department,
        this.city,
        this.pollutant
      );
    },
    changeDataset() {
      this.dataCollectionOptions.scales.y.title.text = this.$t("ton/year");
      this.dataCollectionOptions2.title.text = this.$t("ton/year");
    },
    loadData(identify = 0) {
      // console.log("loadData identify ", identify);
      // console.log("loadData index ", index);

      if (identify > 0) {
        this.loading = false;
        this.$alert(this.$t("Cannot filter by sub sub sector"));
        return;
      }

      let requestPath = "/admin/getAgriculturePollutionSubSector";

      if (
        this.pollutant == null ||
        this.pollutant.id == null ||
        this.pollutant.id == -1
      ) {
        this.$alert(this.$t("Please choose a pollutant"));
        return;
      }
      if (this.pollutant.id < 1) {
        return;
      }
      this.dataActive = false;
      this.loading = true;

      axios
        .post(requestPath, {
          ColumnName: this.pollutant.id,
          pollutionType: this.pollutant.category.toLowerCase(),
          SectorID: null,
          selectedYear: this.year,
          selectedNfr: `${this.nfrId == 0 ? null : this.nfrId}`,
        })
        .then((response) => {
          if (!response.data.Data) {
            this.loading = false;
            this.$alert(this.$t("No data available"));
            return;
          }

          const jsonData = response.data.Data;

          const ids = jsonData.map((item) => item.result_id);

          const jsonLabels = jsonData.map((item) => item.result_type);
          const jsonDataArr = jsonData.map((item) => item.value);

          var datasetCollection = initDataCollection();
          datasetCollection.labels = jsonLabels;
          datasetCollection.datasets[0].data = jsonDataArr;
          datasetCollection.datasets[0].backgroundColor = this.$datasetColor;
          this.dataCollection = datasetCollection;
          this.governmentIds = ids;
          this.loading = false;
          this.dataActive = true;
        });
    },
  },
  components: {
    "pollutant-select": PollutantSelect,
    "nfr-select": NfrSelect,
    chart: Chart,
    "years-select": YearsSelect,
  },
};
</script>

<style>
.chart-container {
  height: 100%;
  width: 100%;
}
</style>