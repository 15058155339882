export default {
  "اسوان": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aswan"])},
  "اسيوط": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assiut"])},
  "الاسكندريه": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alexandria"])},
  "الاسماعيليه": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ismailia"])},
  "الاقصر": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luxor"])},
  "البحر الاحمر": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Red Sea"])},
  "البحيره": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beheira"])},
  "الجيزه": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Giza"])},
  "الدقهليه": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dakahlia"])},
  "السويس": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suez"])},
  "الشرقيه": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sharqia"])},
  "الغربيه": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gharbia"])},
  "الفيوم": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fayoum"])},
  "القاهره": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cairo"])},
  "القليوبيه": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qalyubia"])},
  "المنوفيه": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monufia"])},
  "المنيا": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minya"])},
  "الوادي الجديد": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Valley"])},
  "بنى سويف": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beni Suef"])},
  "بورسعيد": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Port Said"])},
  "جنوب سيناء": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["South Sinai"])},
  "دمياط": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Damietta"])},
  "سوهاج": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sohag"])},
  "شمال سيناء": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["North Sinai"])},
  "قنا": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qena"])},
  "كفر الشيخ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kafr El-Sheikh"])},
  "مطروح": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Matrouh"])},
  "كل الاقاليم": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All Regions"])},
  "إقليم الإسكندرية": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alexandria Region"])},
  "إقليم الدلتا": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delta Region"])},
  "إقليم وسط الصعيد": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Central Upper Egypt Region"])},
  "إقليم شمال الصعيد": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["North Upper Egypt Region"])},
  "إقليم جنوب الصعيد": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["South Upper Egypt Region"])},
  "إقليم قناة السويس": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suez Canal Region"])},
  "إقليم القاهرة الكبري": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Greater Cairo Region"])}
}