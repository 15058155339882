//Facility Administration
const AdminFacilities = () => import("../components/admin/AdminFacilities");
const AdminFacilitySearch = () => import("../components/admin/AdminFacilitySearchForm");
const AdminFacilityDelete = () => import("../components/admin/AdminFacilityDeleteForm");


//Users Administration
const CreateAuthority = () => import("../components/admin/user/CreateAuthority");
const SearchAuthority = () => import("../components/admin/user/SearchAuthority");
const CreateUser = () => import("../components/admin/user/createUser.vue");
const EditUser = () => import("../components/admin/user/editUser");
const CreateUserType = () => import("../components/admin/user/createUserType");
const SearchUser = () => import("../components/admin/user/searchUser");
const Admin = () => import("../components/admin/Admin.vue");
const AdminMainPage = () => import("../components/admin/main.vue");
const AdminHomePage = () => import("../components/admin/AdminHomePage.vue");
const AdminCardsHome = () => import("../components/admin/AdminCardsHome");

//Sectors Administration
const CreateSectors = () => import("../components/admin/CreateSectors.vue");
const AddSector = () => import("../components/admin/AddSector.vue");
const AddSemiSector = () => import("../components/admin/AddSemiSector");
const AddSubSector = () => import("../components/admin/AddSubSector");
const CreatePollutant = () => import("../components/admin/CreatePollutant");
const CreateAgricultureAnimals = () => import("../components/admin/agriculture/AddAgriculturalAnimals.vue");

const LawsandRegulations = () => import("../components/LawsAndRegulations.vue");
const ImportPage = () => import("../components/Import.vue");
const ImportInquiry = () => import("../components/ImportInquiry.vue");
const NotFoundComponent = () => import("../components/NotFound.vue");

//agriculture Administration
const AgriculturePollutionCalculations = () => import("../components/admin/agriculture/AgriculturePollutionCalculations.vue");
const AgricultureRequests = () => import("../components/admin/agriculture/AgricultureRequests.vue")
const EditAgriculture = () => import("../components/admin/agriculture/EditAgriculture.vue");
const EditAnimals = () => import("../components/admin/agriculture/EditAnimals.vue");
const SearchAgriculture = () => import("../components/admin/agriculture/SearchAgriculture.vue");
const SearchAnimals = () => import("../components/admin/agriculture/SearchAnimals.vue");
const SearchAgricultureCategories = () => import("../components/admin/agriculture/SearchAgricultureCategories.vue");
const SearchAnimalsCategories = () => import("../components/admin/agriculture/SearchAnimalsCategories.vue");
const ChatPage = () => import("../components/admin/ChatPage.vue")

import { userTypes} from "./userTypes";

export default [{
    name: "Admin",
    path: "/admin",
    component: AdminMainPage,
    meta: {
        requiresAuth: true,
        allowedUsers: [
            userTypes.Super,
            userTypes.Admin,
            userTypes.ContentCreator,
            userTypes.DecisionMaker,
        ],
    },
    children: [
        {
            path: "home",
            meta: {
                requiresAuth: true,
                allowedUsers: [
                    userTypes.Super,
                    userTypes.Admin,
                    userTypes.ContentCreator,
                    userTypes.DecisionMaker,
                ],
            },
            component: Admin,
            children: [
                {
                    name: "AdminHome",
                    path: "stats",
                    meta: {
                        requiresAuth: true,
                        allowedUsers: [
                            userTypes.Super,
                            userTypes.Admin,
                            userTypes.ContentCreator,
                            userTypes.DecisionMaker,
                        ],
                    },
                    component: AdminHomePage,
                },
                {
                    name: "CreateAuthority",
                    path: "/user/authority",
                    meta: {
                        requiresAuth: true,
                        allowedUsers: [
                            userTypes.Super,
                            userTypes.Admin,
                            userTypes.ContentCreator,
                            userTypes.DecisionMaker,
                        ],
                    },
                    component: CreateAuthority,
                    props: true,
                },
                {
                    name: "SearchAuthority",
                    path: "/user/searchauthority",
                    meta: {
                        requiresAuth: true,
                        allowedUsers: [
                            userTypes.Super,
                            userTypes.Admin,
                            userTypes.ContentCreator,
                            userTypes.DecisionMaker,
                        ],
                    },
                    component: SearchAuthority,
                },
                {
                    name: "CreateUser",
                    path: "/user/create",
                    meta: {
                        requiresAuth: true,
                        allowedUsers: [
                            userTypes.Super,
                            userTypes.Admin,
                            userTypes.ContentCreator,
                            userTypes.DecisionMaker,
                        ],
                    },
                    component: CreateUser,
                },

                {
                    name: "EditUser",
                    path: "/user/Edit",
                    meta: {
                        requiresAuth: true,
                        allowedUsers: [
                            userTypes.Super,
                            userTypes.Admin,
                            userTypes.ContentCreator,
                            userTypes.DecisionMaker,
                        ],
                    },
                    component: EditUser,
                },
                {
                    name: "CreateUserType",
                    path: "/user/type/create",
                    meta: {
                        requiresAuth: true,
                        allowedUsers: [
                            userTypes.Super,
                            userTypes.Admin,
                            userTypes.ContentCreator,
                            userTypes.DecisionMaker,
                        ],
                    },
                    component: CreateUserType,
                },
                {
                    name: "SearchUser",
                    path: "/user/search",
                    meta: {
                        requiresAuth: true,
                        allowedUsers: [
                            userTypes.Super,
                            userTypes.Admin,
                            userTypes.ContentCreator,
                            userTypes.DecisionMaker,
                        ],
                    },
                    component: SearchUser,
                },
                {
                    name: "AdminCreateFacility",
                    path: "/facility/create",
                    meta: {
                        requiresAuth: true,
                        allowedUsers: [
                            userTypes.Super,
                            userTypes.Admin,
                            userTypes.ContentCreator,
                            userTypes.DecisionMaker,
                        ],
                    },
                    component: AdminFacilities,
                    props: { show: "createFacility" },
                },
                {
                    name: "EditFacility",
                    path: "/facility/edit",
                    meta: {
                        requiresAuth: true,
                        allowedUsers: [
                            userTypes.Super,
                            userTypes.Admin,
                            userTypes.ContentCreator,
                            userTypes.DecisionMaker,
                        ],
                    },
                    component: AdminFacilities,
                    props: { show: "editFacility" },
                },
                {
                    name: "SearchFacility",
                    path: "/facility/search",
                    meta: {
                        requiresAuth: true,
                        allowedUsers: [
                            userTypes.Super,
                            userTypes.Admin,
                            userTypes.ContentCreator,
                            userTypes.DecisionMaker,
                        ],
                    },
                    component: AdminFacilitySearch,
                },
                {
                    name: "DeleteFacility",
                    path: "/facility/delete",
                    meta: {
                        requiresAuth: true,
                        allowedUsers: [
                            userTypes.Super,
                            userTypes.Admin,
                            userTypes.ContentCreator,
                            userTypes.DecisionMaker,
                        ],
                    },
                    component: AdminFacilityDelete,
                },
                {
                    name: "CreateSector",
                    path: "/Sector/create",
                    meta: {
                        requiresAuth: true,
                        allowedUsers: [
                            userTypes.Super,
                            userTypes.Admin,
                            userTypes.ContentCreator,
                            userTypes.DecisionMaker,
                        ],
                    },
                    component: AddSector,
                    props: true,
                },
                {
                    name: "AddSector",
                    path: "/Sector/create",
                    meta: {
                        requiresAuth: true,
                        allowedUsers: [
                            userTypes.Super,
                            userTypes.Admin,
                            userTypes.ContentCreator,
                            userTypes.DecisionMaker,
                        ],
                    },
                    component: CreateSectors,
                },

                {
                    name: "EditSector",
                    path: "/Sector/Edit",
                    meta: {
                        requiresAuth: true,
                        allowedUsers: [
                            userTypes.Super,
                            userTypes.Admin,
                            userTypes.ContentCreator,
                            userTypes.DecisionMaker,
                        ],
                    },
                    component: CreateSectors,

                    props: true,
                },
                {
                    name: "EditSemiSector",
                    path: "/Sector/industrialsectors/Edit",
                    meta: {
                        requiresAuth: true,
                        allowedUsers: [
                            userTypes.Super,
                            userTypes.Admin,
                            userTypes.ContentCreator,
                            userTypes.DecisionMaker,
                        ],
                    },
                    component: CreateSectors,
                    props: true,
                },
                {
                    name: "CreateIndustrialSemiSector",
                    path: "/Sector/industrialsectors/create",
                    meta: {
                        requiresAuth: true,
                        allowedUsers: [
                            userTypes.Super,
                            userTypes.Admin,
                            userTypes.ContentCreator,
                            userTypes.DecisionMaker,
                        ],
                    },
                    component: CreateSectors,
                },
                {
                    name: "AddIndustrialSemiSector",
                    path: "/Sector/industrialsectors/Add",
                    meta: {
                        requiresAuth: true,
                        allowedUsers: [
                            userTypes.Super,
                            userTypes.Admin,
                            userTypes.ContentCreator,
                            userTypes.DecisionMaker,
                        ],
                    },
                    component: AddSemiSector,
                },
                {
                    name: "AddSubSector",
                    path: "/Sector/subsectors/create",
                    meta: {
                        requiresAuth: true,
                        allowedUsers: [
                            userTypes.Super,
                            userTypes.Admin,
                            userTypes.ContentCreator,
                            userTypes.DecisionMaker,
                        ],
                    },
                    component: AddSubSector,
                    props: true,
                },
                {
                    name: "CreateSubSector",
                    path: "/Sector/subsectors/create",
                    meta: {
                        requiresAuth: true,
                        allowedUsers: [
                            userTypes.Super,
                            userTypes.Admin,
                            userTypes.ContentCreator,
                            userTypes.DecisionMaker,
                        ],
                    },
                    component: CreateSectors,
                    props: true,
                },
                {
                    name: "EditSubSector",
                    path: "/Sector/Edit",
                    meta: {
                        requiresAuth: true,
                        allowedUsers: [
                            userTypes.Super,
                            userTypes.Admin,
                            userTypes.ContentCreator,
                            userTypes.DecisionMaker,
                        ],
                    },
                    component: CreateSectors,

                    props: true,
                },
                {
                    name: "CreatePollutant",
                    path: "/Sector/pollutant/create",
                    meta: {
                        requiresAuth: true,
                        allowedUsers: [
                            userTypes.Super,
                            userTypes.Admin,
                            userTypes.ContentCreator,
                            userTypes.DecisionMaker,
                        ],
                    },
                    component: CreatePollutant,
                    props: { show: "createPollutant" },
                },
                {
                    name: "CreateAgricultureAnimals",
                    path: "/AgricultureAnimals/agricultureAnimals/create",
                    meta: {
                        requiresAuth: true,
                        allowedUsers: [
                            userTypes.Super,
                            userTypes.Admin,
                            userTypes.DataVerifier,
                        ],
                    },
                    component: CreateAgricultureAnimals,
                    props: true,
                },
                {
                    name: "EditAgriculture",
                    path: "/AgricultureAnimals/EditAgriculture",
                    meta: {
                        requiresAuth: true,
                        allowedUsers: [
                            userTypes.Super,
                            userTypes.Admin,
                            userTypes.DataVerifier,
                            userTypes.AgricultureUser,
                        ],
                    },
                    component: EditAgriculture,
                    props: true,
                },
                {
                    name: "SearchAgricultureCategories",
                    path: "/AgricultureAnimals/SearchAgricultureCategories",
                    meta: {
                        requiresAuth: true,
                        allowedUsers: [
                            userTypes.Super,
                            userTypes.Admin,
                            userTypes.DataVerifier,
                            userTypes.AgricultureUser,
                        ],
                    },
                    component: SearchAgricultureCategories,
                    props: true,
                },
                {
                    name: "SearchAnimalsCategories",
                    path: "/AgricultureAnimals/SearchAnimalsCategories",
                    meta: {
                        requiresAuth: true,
                        allowedUsers: [
                            userTypes.Super,
                            userTypes.Admin,
                            userTypes.DataVerifier,
                            userTypes.AgricultureUser,
                        ],
                    },
                    component: SearchAnimalsCategories,
                    props: true,
                },
                {
                    name: "SearchAgriculture",
                    path: "/AgricultureAnimals/searchagriculture",
                    meta: {
                        requiresAuth: true,
                        allowedUsers: [
                            userTypes.Super,
                            userTypes.Admin,
                            userTypes.DataVerifier,
                            userTypes.AgricultureUser,
                        ],
                    },
                    component: SearchAgriculture,
                    props: true,
                },
                {
                    name: "SearchAnimals",
                    path: "/AgricultureAnimals/searchanimals",
                    meta: {
                        requiresAuth: true,
                        allowedUsers: [
                            userTypes.Super,
                            userTypes.Admin,
                            userTypes.DataVerifier,
                            userTypes.AgricultureUser,
                        ],
                    },
                    component: SearchAnimals,
                    props: true,
                },
                {
                    name: "EditAnimals",
                    path: "/AgricultureAnimals/EditAnimals",
                    meta: {
                        requiresAuth: true,
                        allowedUsers: [
                            userTypes.Super,
                            userTypes.Admin,
                            userTypes.DataVerifier,
                            userTypes.AgricultureUser,
                        ],
                    },
                    component: EditAnimals,
                    props: true,
                },
                {
                    name: "AgriculturePollutionCalculations",
                    path: "/AgricultureAnimals/AgriculturePollutionCalculations",
                    meta: {
                        requiresAuth: true,
                        allowedUsers: [
                            userTypes.Super,
                            userTypes.Admin,
                            userTypes.DataVerifier,
                        ],
                    },
                    component: AgriculturePollutionCalculations,
                    props: { superId: 1 },
                },
                {
                    name: "SewagePollutionCalculations",
                    path: "/AgricultureAnimals/SewagePollutionCalculations",
                    meta: {
                        requiresAuth: true,
                        allowedUsers: [
                            userTypes.Super,
                            userTypes.Admin,
                            userTypes.DataVerifier,
                        ],
                    },
                    component: AgriculturePollutionCalculations,
                    props: { filterId: 4, superId: 1 },
                },
                {
                    name: "CreateRegulations",
                    path: "/regulations/create",
                    component: LawsandRegulations,
                    meta: {
                        requiresAuth: true,
                        allowedUsers: [
                            userTypes.Super,
                            userTypes.Admin,
                            userTypes.ContentCreator,
                            userTypes.DecisionMaker,
                        ],
                    },
                    props: { type: "create" },
                },
                {
                    name: "DeleteRegulations",
                    path: "/regulations/delete",
                    component: LawsandRegulations,
                    meta: {
                        requiresAuth: true,
                        allowedUsers: [
                            userTypes.Super,
                            userTypes.Admin,
                            userTypes.ContentCreator,
                            userTypes.DecisionMaker,
                        ],
                    },
                    props: { type: "delete" },
                },
                {
                    name: "ImportFacilities",
                    path: "/importFiles/facilities",
                    component: ImportPage,
                    meta: {
                        requiresAuth: true,
                        allowedUsers: [
                            userTypes.Super,
                            userTypes.Admin,
                            userTypes.ContentCreator,
                            userTypes.DecisionMaker,
                        ],
                    },
                    props: { type: "Facilities", isAdmin: true },
                },

                {
                    name: "ImportScannedFacilities",
                    path: "/importFiles/facilities/scanned",
                    component: ImportPage,
                    meta: {
                        requiresAuth: true,
                        allowedUsers: [
                            userTypes.Super,
                            userTypes.Admin,
                            userTypes.ContentCreator,
                            userTypes.DecisionMaker,
                        ],
                    },
                    props: { type: "ScannedFacilities", isAdmin: true },
                },
                {
                    name: "ImportSectorRules",
                    path: "/importFiles/sectorrules",
                    component: ImportPage,
                    meta: {
                        requiresAuth: true,
                        allowedUsers: [
                            userTypes.Super,
                            userTypes.Admin,
                            userTypes.ContentCreator,
                            userTypes.DecisionMaker,
                        ],
                    },
                    props: { type: "SectorRules", isAdmin: true },
                },
                {
                    name: "ImportControl",
                    path: "/importFiles/control",
                    component: ImportPage,
                    meta: {
                        requiresAuth: true,
                        allowedUsers: [
                            userTypes.Super,
                            userTypes.Admin,
                            userTypes.ContentCreator,
                            userTypes.DecisionMaker,
                        ],
                    },
                    props: { type: "Control", isAdmin: true },
                },
                {
                    name: "ImportFuel",
                    path: "/importFiles/fuel",
                    component: ImportPage,
                    meta: {
                        requiresAuth: true,
                        allowedUsers: [
                            userTypes.Super,
                            userTypes.Admin,
                            userTypes.ContentCreator,
                            userTypes.DecisionMaker,
                        ],
                    },
                    props: { type: "Fuel", isAdmin: true },
                },
                {
                    name: "ImportAgriculture",
                    path: "/importFiles/agriculture",
                    component: ImportPage,
                    meta: {
                        requiresAuth: true,
                        allowedUsers: [
                            userTypes.Super,
                            userTypes.Admin,
                            userTypes.ContentCreator,
                            userTypes.DecisionMaker,
                        ],
                    },
                    props: {
                        type: "Agriculture", isAdmin: true
                    },
                },
                {
                    name: "ImportCategories",
                    path: "/importFiles/categories",
                    component: ImportPage,
                    meta: {
                        requiresAuth: true,
                        allowedUsers: [
                            userTypes.Super,
                            userTypes.Admin,
                            userTypes.ContentCreator,
                            userTypes.DecisionMaker,
                        ],
                    },
                    props: { type: "Categories", isAdmin: true },
                },
                {
                    name: "ImportGovernorate",
                    path: "/importMaps/governorate",
                    component: ImportPage,
                    meta: {
                        requiresAuth: true,
                        allowedUsers: [userTypes.Super, userTypes.Admin],
                    },
                    props: { type: "GovernorateMap", isAdmin: true },
                },
                {
                    name: "ImportPbf",
                    path: "/importMaps/pbf",
                    component: ImportPage,
                    meta: {
                        requiresAuth: true,
                        allowedUsers: [userTypes.Super, userTypes.Admin],
                    },
                    props: { type: "Pbf", isAdmin: true },
                },
                {
                    name: "ImportAgricultureSector",
                    path: "/AgricultureAnimals/SectorFile",
                    component: ImportPage,
                    meta: {
                        requiresAuth: true,
                        allowedUsers: [
                            userTypes.Super,
                            userTypes.Admin,
                            userTypes.AgricultureUser,
                        ],
                    },
                    props: { type: "SectorAndData", isAdmin: true, superId: 1, template: "AgricultureSectors.xlsx" },
                },
                {
                    name: "ImportAgricultureData",
                    path: "/AgricultureAnimals/agricultureDataFile",
                    component: ImportPage,
                    meta: {
                        requiresAuth: true,
                        allowedUsers: [
                            userTypes.Super,
                            userTypes.Admin,
                            userTypes.AgricultureUser,
                        ],
                    },
                    props: { type: "agricultureFileLookup", isAdmin: true, superId: 1, template: "AgricultureData.xlsx" },
                },
                {
                    name: "ImportAgricultureLookupData",
                    path: "/AgricultureAnimals/agricultureLookupFile",
                    component: ImportPage,
                    meta: {
                        requiresAuth: true,
                        allowedUsers: [
                            userTypes.Super,
                            userTypes.Admin,
                            userTypes.AgricultureUser,
                        ],
                    },
                    props: { type: "agricultureFileLookup", isAdmin: true, superId: 1, template: "LivestockDistributionData.xlsx" },
                },
                {
                    name: "ImportAgricultureCalculate",
                    path: "/AgricultureAnimals/agricultureEquations",
                    component: ImportPage,
                    meta: {
                        requiresAuth: true,
                        allowedUsers: [
                            userTypes.Super,
                            userTypes.Admin,
                            userTypes.AgricultureUser,
                        ],
                    },
                    props: { type: "equation", superId: 1, template: "AgricultureEquations.xlsx", isAdmin: true },
                },


                {
                    name: "ImportFactories",
                    path: "/importFiles/facilities/factories",
                    component: ImportPage,
                    meta: {
                        requiresAuth: true,
                        allowedUsers: [
                            userTypes.Super,
                            userTypes.Admin,
                            userTypes.ContentCreator,
                            userTypes.DecisionMaker,
                        ],
                    },
                    props: { type: "Factories", isAdmin: true },
                },

                {
                    name: "CompostingImportFacilities",
                    path: "/SolidWaste/composting",
                    component: ImportPage,
                    meta: {
                        requiresAuth: true,
                        allowedUsers: [
                            userTypes.Super,
                            userTypes.Admin,
                            userTypes.ContentCreator,
                            userTypes.DecisionMaker,
                        ],
                    },
                    props: { type: "Facilities", isAdmin: true, superId: 4, template: "CompostingData.xlsx" },
                },
                {
                    name: "LandfillsImportFacilities",
                    path: "/SolidWaste/landfills",
                    component: ImportPage,
                    meta: {
                        requiresAuth: true,
                        allowedUsers: [
                            userTypes.Super,
                            userTypes.Admin,
                            userTypes.ContentCreator,
                            userTypes.DecisionMaker,
                        ],
                    },
                    props: { type: "Facilities", isAdmin: true, superId: 4, template: "LandfillsData.xlsx" },
                },
                {
                    name: "WWTPImportFacilities",
                    path: "/SolidWaste/wwtp",
                    component: ImportPage,
                    meta: {
                        requiresAuth: true,
                        allowedUsers: [
                            userTypes.Super,
                            userTypes.Admin,
                            userTypes.ContentCreator,
                            userTypes.DecisionMaker,
                        ],
                    },
                    props: { type: "Facilities", isAdmin: true, superId: 5, template: "wwtpData.xlsx" },
                },
                {
                    name: "SolidWasteImportFile",
                    path: "/SolidWaste/annualData",
                    component: ImportPage,
                    meta: {
                        requiresAuth: true,
                        allowedUsers: [
                            userTypes.Super,
                            userTypes.Admin,
                            userTypes.ContentCreator,
                            userTypes.DecisionMaker,
                        ],
                    },
                    props: { type: "SectorAndData", isAdmin: true, superId: 4, template: "SolidWasteMiscData.xlsx" },
                },

                {
                    name: "SolidWasteImportSectorFile",
                    path: "/SolidWaste/SectorFile/",
                    component: ImportPage,
                    meta: {
                        requiresAuth: true,
                        allowedUsers: [
                            userTypes.Super,
                            userTypes.Admin,
                            userTypes.WasteUser,
                            userTypes.WasteUser,
                        ],
                    },
                    props: { type: "SectorAndData", isAdmin: true, superId: 4, template: "SolidWasteSectors.xlsx" },
                },
                {
                    name: "SolidWasteImportEquations",
                    path: "/SolidWaste/equations/",
                    component: ImportPage,
                    meta: {
                        requiresAuth: true,
                        allowedUsers: [
                            userTypes.Super,
                            userTypes.Admin,
                            userTypes.WasteUser,
                        ],
                    },
                    props: { type: "equation", isAdmin: true, superId: 4, template: "SolidWasteEquations.xlsx" },
                },
                {
                    name: "SolidWastePollutionCalculations",
                    path: "/SolidWaste/calculation/SolidWastePollutionCalculations",
                    meta: {
                        requiresAuth: true,
                        allowedUsers: [
                            userTypes.Super,
                            userTypes.Admin,
                            userTypes.DataVerifier,
                        ],
                    },
                    component: AgriculturePollutionCalculations,
                    props: { type: "solidWasteCalculate", isAdmin: true, superId: 4 },
                },
                {
                    name: "ImportInquiry",
                    path: "/import/inquiry",
                    component: NotFoundComponent,
                    meta: {
                        requiresAuth: true,
                        allowedUsers: [
                            userTypes.Super,
                            userTypes.Admin,
                            userTypes.ContentCreator,
                            userTypes.DecisionMaker,
                        ],
                    },
                },
                {
                    name: "Admin_Requests",
                    path: "/AgricultureAnimals/requests/Requests",
                    meta: {
                        requiresAuth: true,
                        allowedUsers: [
                            userTypes.Super,
                            userTypes.Admin,
                            userTypes.DataVerifier,
                            userTypes.AgricultureUser,
                        ],
                    },
                    component: AgricultureRequests,
                    props: { type: "Admin" },
                },
                {
                    name: "Admin_chat",
                    path: "/AgricultureAnimals/requests/chat",
                    meta: {
                        requiresAuth: true,
                        allowedUsers: [
                            userTypes.Super,
                            userTypes.Admin,
                            userTypes.DataVerifier,
                            userTypes.AgricultureUser,
                        ],
                    },
                    component: ChatPage,
                    props: { type: "Admin" },
                },
            ],
        },
    ],
}
];