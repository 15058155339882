export default {
  "Super Sector": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["القطاع الرئيسي"])},
  "Super Sectors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["القطاعات الرئيسية"])},
  "Sector": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["القطاع"])},
  "createSuperSector": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إنشاء قطاع رئيسي"])},
  "createSector": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إنشاء قطاع"])},
  "createIndustrialSemiSector": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["انشاء قطاع صناعي مجمع"])},
  "Industrial Semisector": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["القطاع الصناعي المجمع"])},
  "Sector Short Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاسم المختصر"])},
  "createSubSector": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["انشاء قطاع فرعي"])},
  "Full SIC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SIC كامل"])},
  "Add a sector": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضافة قطاع"])},
  "Add Agriculture Category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضافة قطاع زراعي"])},
  "Edit Agriculture Category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعديل قطاع زراعي"])},
  "Agriculture Sector": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قطاع زراعي"])},
  "Animal Type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوع الحيوان"])},
  "NFR Code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كود NFR"])},
  "Number of Animals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد الماشبة"])},
  "Animal Sector": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قطاع الماشية"])},
  "SubSector": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التصنيف الفرعي"])},
  "New Sector Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم القطاع الجديد"])},
  "Create a New Pollutant Item": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إنشاء ملوث جديد"])},
  "Create Agriculture and Animals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إنشاء محاصيل وماشية"])},
  "Pollutant Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم الملوث"])},
  "Pollutant Type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فئة الملوث"])},
  "Pollutant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الملوث"])},
  "Cannot filter by selection. Please check the available options.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا يمكن التصفية حسب الاختيار. يرجى التحقق من الخيارات المتاحة."])},
  "Pollutant Types": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أنواع الملوثات"])}
}