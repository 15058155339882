export default {
  "Please Choose a Pollutant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يرجى اختيار ملوث"])},
  "Carbon Monoxide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أول أكسيد الكربون (CO)"])},
  "Nitrogen Oxides": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أكاسيد النيتروجين (NOx)"])},
  "Particulate Matter (PM10)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الجسيمات العالقة (PM10)"])},
  "Volatile Organic Compounds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المركبات العضوية المتطايرة (VOCs)"])},
  "Sulfur Oxides": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أكاسيد الكبريت (SOx)"])},
  "Nitrogen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نيتروجين (N2)"])},
  "Ammonia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أمونيا (NH3)"])},
  "Particles Smaller than 2.5mm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المواد الصلبة أقل من 2.5 مم (PM2.5)"])},
  "Methane": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ميثان (CH4)"])},
  "Carbon Dioxide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ثاني اوكسيد الكربون (CO2)"])},
  "Ammonia from Livestock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أمونيا ناتجه من الماشية"])},
  "Ammonia from Poultry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أمونيا ناتجة من الدواجن"])},
  "PAH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الهيدروكربونات العطرية الحلقية (PAHs)"])},
  "Nickel (Ni)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["النيكل (Ni)"])},
  "Chromium (Cr)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الكروم (Cr)"])},
  "Lead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرصاص (Pb)"])},
  "Pb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرصاص (Pb)"])},
  "Al": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الألمنيوم (Al)"])},
  "K": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البوتاسيوم (K)"])},
  "Fluorine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الفلور (F)"])},
  "Hydrogen Chloride (HCL)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حمض الهيدروكلوريك (HCl)"])},
  "Total Organic Carbon (TOCs)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الكربون العضوي الكلي (TOCs)"])},
  "Hydrogen Sulfide (H2S)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كبريتيد الهيدروجين (H2S)"])},
  "Total Hydrocarbons (THC)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الهيدروكربونات الكلية (THC)"])},
  "Caprolactam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كابرولاكتام"])},
  "caprolactam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كابرولاكتام"])},
  "Heat Transfer Media (HTM)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وسائط نقل الحرارة (HTM)"])},
  "Mercury": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الزئبق (Hg)"])},
  "Cadmium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الكادميوم (Cd)"])},
  "Benzene": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البنزين (C6H6)"])},
  "Black Smoke Opacity (BSO)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الدخان الأسود (BSO)"])},
  "BSO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الدخان الأسود"])},
  "HTM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وسائط نقل الحرارة (HTM)"])},
  "Total Florides": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الفلوريدات الكلية"])},
  "Benzo-a-pyrene": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بينزو-أ-بيرين"])},
  "Tar acids": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أحماض القطران"])},
  "Tar bases": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قواعد القطران"])},
  "Tar oil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["زيت القطران"])},
  "Tar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["القطران"])},
  "Cobalt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الكوبالت (Co)"])},
  "Soot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السناج"])},
  "Total Suspended Particles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الجسيمات الصلبة الكلية (TSP)"])},
  "THC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الهيدروكربونات الكلية (THC)"])},
  "TOCs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الكربون العضوي الكلي (TOCs)"])},
  "H2S": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كبريتيد الهيدروجين (H2S)"])},
  "HCL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حمض الهيدروكلوريك (HCl)"])},
  "Polycyclic aromatic hydrocarbons (PAHs)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الهيدروكربونات العطرية الحلقية (PAHs)"])},
  "Ni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["النيكل (Ni)"])},
  "N2O": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ثاني أكسيد النيتروجين (N2O)"])},
  "Please select a pollutant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يرجى اختيار ملوث"])},
  "Dissolved Solids": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المواد الصلبة الذائبة (DS)"])},
  "Suspended Solids": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المواد الصلبة العالقة (SS)"])},
  "Chemical Oxygen Demand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أكسجين كيميائي مستهلك (COD)"])},
  "Biochemical Oxygen Demand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أكسجين حيوي ممتص (BOD)"])},
  "Total Phosphorus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الفسفور الكلي (TP)"])},
  "Sulfates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الكبريتات (SO4)"])},
  "Nitrates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["النترات (NO3)"])},
  "Total Nitrogen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["النيتروجين الكلي (TN)"])},
  "Temperature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["درجة الحرارة (Temp)"])},
  "Heavy Metals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معادن ثقيلة (HM)"])},
  "E. coli Count CFU/day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عد البكتريا القولونية CFU/day"])},
  "Oils and Greases": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["زيوت وشحوم (O&G)"])},
  "pH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["درجة الحموضة (pH)"])},
  "Alkalinity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["القلوية (Alkalinity)"])},
  "Aluminum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الألمنيوم (Al)"])},
  "Iron": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحديد (Fe)"])},
  "AOX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AOX"])},
  "TOC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TOC"])},
  "Urea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يوريا (Urea)"])},
  "Fluoride": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فلوريد (F)"])},
  "Total Solids": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المواد الصلبة الكلية (TS)"])},
  "Chromium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الكروم (Cr)"])},
  "chlorides": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الكلوريدات (Cl)"])},
  "sodium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الصوديوم (Na)"])},
  "Magnesium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المغنيسيوم (Mg)"])},
  "calcium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الكالسيوم (Ca)"])},
  "boron": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البورون (B)"])},
  "zinc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الزنك (Zn)"])},
  "silicon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السيليكون (Si)"])},
  "Copper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["النحاس (Cu)"])},
  "Hexavalent Chromium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الكروم سداسي التكافؤ (Cr VI)"])},
  "Tin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["القصدير (Sn)"])},
  "Antimony": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأنتيمون (Sb)"])},
  "Barium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الباريوم (Ba)"])},
  "Phenol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الفينول (C6H5OH)"])},
  "Silver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فضة (Ag)"])},
  "Cerium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السيريوم (Ce)"])},
  "Nitrogen Ammonia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["النيتروجين الأموني (NH4)"])},
  "Cyanide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السيانيد (CN)"])},
  "TKN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TKN"])},
  "Formaldehyde": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الفورمالديهايد (HCHO)"])},
  "N-Kj": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N-Kj"])},
  "Furfural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الفورفورال (Furfural)"])},
  "NMP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NMP"])},
  "MEK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MEK"])},
  "Benzene (C6H6)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البنزين (C6H6)"])},
  "Dissolved HCs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الهيدروكربونات الذائبة (HCs)"])},
  "Potassium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البوتاسيوم (K)"])},
  "Phosphate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فوسفات (PO4)"])},
  "sulfide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الكبريتيد (S2-)"])},
  "chlorine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الكلور (Cl)"])},
  "po4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فوسفات (PO4)"])},
  "potassium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البوتاسيوم (K)"])},
  "dissolved HCs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الهيدروكربونات الذائبة (HCs)"])},
  "furfural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الفورفورال (Furfural)"])},
  "copper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["النحاس (Cu)"])},
  "Nickel ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["النيكل (Ni)"])},
  "Nickel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["النيكل (Ni)"])},
  "Ag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فضة (Ag)"])},
  "Ce": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السيريوم (Ce)"])},
  "cadmium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الكادميوم (Cd)"])},
  "Nitrogen ammonia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["النيتروجين الأموني (NH4)"])}
}