import { createI18n } from "vue-i18n";

/**
 * Load locale messages
 *
 * The loaded `JSON` locale messages is pre-compiled by `@intlify/vue-i18n-loader`, which is integrated into `vue-cli-plugin-i18n`.
 * See: https://github.com/intlify/vue-i18n-loader#rocket-i18n-resource-pre-compilation
 */
function loadLocaleMessages() {
  const locales = require.context(
    "./locales",
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i
  );
  const messages = {};

  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const localePrefix = matched[1];

      // Determine the locale based on the filename prefix
      const locale = localePrefix.startsWith("en") ? "en" : "ar";

      // Merge messages to handle multiple files for the same locale
      messages[locale] = { ...messages[locale], ...locales(key).default };
    }
  });
  return messages;
}

const i18n = createI18n({
  legacy: false,
  globalInjection: true,
  locale: process.env.VUE_APP_I18N_LOCALE || "en",
  fallbackLocale: false,
  fallbackWarn: true,
  missingWarn: false,
  silentTranslationWarn: true,
  messages: loadLocaleMessages(),
  missing: (locale, key) => {
    // log missing translation
    console.log(`Missing translation for key: ${key} in locale: ${locale}`);
  },
});

export default i18n;
