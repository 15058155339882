export default {
  "Industrial Reports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التقارير الصناعية"])},
  "Agricultural Reports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التقارير الزراعية"])},
  "Water Treatment Reports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تقارير محطات التحلية"])},
  "Commercial Reports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التقارير التجارية"])},
  "Other Reports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تقارير أخرى"])},
  "Dump sites Reports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تقارير مكبات النفايات"])},
  "Sewage station Reports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تقارير محطات الصرف"])},
  "Power Reports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تقارير الطاقة"])},
  "Top Commercial Sectors for Pollution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رسم بياني للقطاعات  التجارية والملوثات الناتجة منها"])},
  "Top Commercial Facilities for Pollution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رسم بياني للمنشآت التجارية الأكثر إنتاجاَ للملوثات"])},
  "Factories Per Commercial Sector": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بيان بأعداد المنشأت في القطاعات التجارية"])},
  "Top Five Pollution Per Commercial Sector": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بيان  بأحمال و أعداد مصادر تلوث الهواء في القطاعات التجارية"])},
  "Top Agricultural Sectors for Pollution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رسم بياني لقطاعات الزراعة والملوثات الناتجة منها"])},
  "Factories Per Agricultural Sector": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بيان بأعداد المنشآت في القطاعات الزراعية"])},
  "Statement of areas for planting different crops": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بيان بمساحات زراعة المحاصيل المختلفة"])},
  "Top Five Pollution Per Agricultural Sector": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بيان  بأحمال تلوث و مساحات مواسم الزراعة"])},
  "Slaughter houses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بيان بالمجازر والمسالخ"])},
  "Top industrial sectors emitting pollution graph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رسم بياني للقطاعات  الصناعية الأكثر إصدارا للملوثات"])},
  "Top industrial facilities emitting pollution graph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رسم بياني للمصانع الأكثر إنتاجاَ للملوثات"])},
  "Factories Per Industrial Sector": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بيان بأعداد المنشأت في القطاعات الصناعية الفرعية"])},
  "Top Five Air Pollution Per Industrial Sector": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بيان  بأحمال و أعداد مصادر تلوث الهواء في القطاعات الصناعية"])},
  "Factories Draining To Sewage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بيان بالمصانع التي تصب مباشرة علي المصارف."])},
  "Factories with dangerous drainage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بيان بالمصانع ذات الصرف الخطر"])},
  "Top Industrial Sectors for Water Usage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بيان بكمية استهلاك  المياة داخل القطاع الصناعي"])},
  "Top Water treatment Facilities for Pollution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رسم بياني لمحطات التحلية الأكثر انتاجا للملوثات."])},
  "Factories Per Water Treatment Sector": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["احصائيات مصادر التلوث بقطاع محطات التحلية"])},
  "Top Five Pollution Per Water Treatment Sector": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بيان بأحمال واعداد محطات التحلية."])},
  "Top Factories causing liquid drainage pollution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بيان بأكثر المحطات المسببة للتلوث السائل"])},
  "Water treatment plants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بيان بمواصفات محطات معالجة مياة الشرب."])},
  "Top Sewage station Facilities for Pollution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رسم بياني للمحطات الصرف الأكثر إنتاجاَ للملوثات."])},
  "Factories Per Sewage station Sector": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بيان  بأحمال و أعداد محطات الصرف."])},
  "Top Five Pollution Per Sewage station Sector": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بيان وخريطة بأماكن وكميات وخطورة مصادر الصرف."])},
  "Pollution sources adding to sewage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مصادر التلوث التي تصب في الصرف"])},
  "Hot areas of sewage dumping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خريطة للمناطق الساخنة للصرف."])},
  "Pollutant weights adding to sewage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رسم بياني بأحمال تلوث المياه بالمصرف."])},
  "Sewage Treatment Stations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بيان بمواصفات محطات معالجة الصرف"])},
  "Liquid waste weights caused by sewage treatment plants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رسم بياني بكمية الملوثات السائلة الناتجة عن محطات الصرف."])},
  "Top Factories In Sewage Draining": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بيان بأكبر المصادر الصناعية للصرف وأنواع المعالج"])},
  "Top Dump sites Sectors for Pollution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رسم بياني لانواع مكبات النفايات والملوثات الناتجة منها."])},
  "Top Dump sites Facilities for Pollution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رسم بياني للمكبات الأكثر إنتاجاَ للملوثات."])},
  "Factories Per Dump sites Sector": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بيان بأعداد مكبات النفايات المختلفة."])},
  "Top Five Pollution Per Dump sites Sector": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بيان  بأحمال و أعداد مصادر تلوث الهواء لأنواع المكبات المختلفة."])},
  "Factories Per City": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بيان بأعداد بالمصادر الملوثة بالمحافظات."])},
  "Pollutant History": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بيان بأحمال الملوث لكل مصدر خلال عدة سنوات."])},
  "Top Facilities Producing Pollutants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بيان بأكثر المصادر إصدارا للملوثات."])},
  "Pollutant Trend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رسم بياني لتغير أحمال التلوث خلال عدة سنوات."])},
  "Top Five Air Pollution Per Governorate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بيان  بأحمال و أعداد مصادر تلوث الهواء لكل محافظة."])},
  "Emissions Per sector": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رسم بياني يقارن أحمال الملوثات بالقطاعات الرئيسية."])},
  "Top Power Sectors for Pollution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اكثر قطاعات الطاقة اصدارا للملوثات"])},
  "Factories Per Power Sector": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بيان بأعداد وأنواع محطات الطاقة."])},
  "Top Five Pollution Per Power station Sector": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بيان  بأحمال تلوث الهواء و أعداد المحطات المختلفة ."])},
  "Top Power Facilities for Pollution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رسم بياني لمحطات الطاقة الأكثر إنتاجاَ للملوثات."])},
  "Factory Count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد المنشآت"])},
  "Sub-Sector": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["القطاع الفرعي"])},
  "Include non-contributing facilities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إدراج أعداد المصادر الغير مؤثرة"])},
  "Has Treatment Units": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يوجد وحدات معالجة"])},
  "Percentage From Overall Sewage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نسبة الصرف الصناعي من إجمالي الصرف"])},
  "Percentage Of Danger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نسبة الصرف الخطر من إجمالي الصرف"])},
  "Availability In Area": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كمياتها بالمنطقة"])},
  "Industrial Overall search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["القطاع الصناعي كله"])},
  "Factories division": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تفاصيل المصانع في القطاع"])},
  "Water Usage Amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كمية استهلاك المياه"])},
  "Product Quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كمية المنتج"])},
  "Product Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم المنتج"])},
  "Sources Count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد المصادر"])},
  "Slaughter Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم المجزرة/المسلخة"])},
  "Source Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم المصدر"])},
  "Source Type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوع المصدر"])},
  "All Main Sectors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كل القطاعات الرئيسية"])},
  "Industrial Sector": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["القطاع الصناعي"])},
  "Dump Sites": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مكبات النفايات"])},
  "Dump sites": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مكبات النفايات"])},
  "Sewage station": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["محطات الصرف"])},
  "Water Distillation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["محطات تحلية المياه"])},
  "Commercial Sector": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["القطاع التجاري"])},
  "Power Station": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قطاع إنتاج الطاقة"])},
  "Ports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المواني"])},
  "Drains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مصارف"])},
  "Start Year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سنة تشغيل المحطة"])},
  "Date Of Renewal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ أعمال الإحلال والتجديد"])},
  "Treatment System Used": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نظام المعالجة المستخدم"])},
  "Treatment System Theoretical Efficiency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["درجة الكفاءة النظرية"])},
  "Treatment System Actual Efficiency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["درجة الكفاءة الفعلية"])},
  "Industrial Sewage Acceptance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تستقبل صرف صناعي"])},
  "Negative Sewage Amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كمية الصرف السلبي"])},
  "Design Energy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الطاقة التصميمية"])},
  "Actual Energy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الطاقة الفعلية"])},
  "Association In Charge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الجهة المسئولة"])},
  "Sludge Quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كمية الحمأة"])},
  "Matching Specifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" الصرف مطابق للمواصفات"])},
  "Sewage treatment plants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["محطات معالجة"])},
  "Treatment Type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوع المعالجة"])},
  "Station Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم المحطة"])},
  "Percentage of facilities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نسبه المنشآت"])},
  "Number of facilities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد المنشآت"])},
  "0: Unlimited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["صفر: دون تحديد"])},
  "Include agriculture lands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إدراج الأراضي الزراعية"])},
  "Failed to load data. Try another options.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا توجد بيانات من فضل جرب اختيارات أخري"])},
  "Distribution of total air pollution loads among sectors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["توزيع اجمالي أحمال تلوث الهواء على القطاعات "])},
  "Waste Water Classification for Industrial Facilities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تصنيف الصرف الناتج عن المنشآت الصناعية"])},
  "Distribution of total pollution loads among sectors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["توزيع اجمالي أحمال التلوث على القطاعات "])},
  "Distribution of the amount of industrial spending among the industrial sub-sectors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["توزيع كمية الصرف الصناعي على القطاعات الصناعية الفرعية"])},
  "Quantity of industrial facilities disbursement according to sub-sector": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كمية صرف المنشات الصناعية طبقا للقطاع الفرعي"])},
  "Quantity of industrial solid waste": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كمية المخلفات الصلبة الصناعية"])},
  "Hazardous industrial waste and methods of disposal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المخلفات الصناعية الخطرة و طرق التخلص منها"])},
  "Industrial Solid Waste": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المخلفات الصلبة الصناعية"])},
  "Waste Quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كمية المخلفات"])},
  "Waste Types": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أنواع المخلفات"])},
  "Waste Disposal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مصير المخلفات"])},
  "Type of waste": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوع المخلف"])},
  "Last Year Value in Feddan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["القيمة السنوية الجديدة بالفدان"])},
  "Second Last Year Value in Feddan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["القيمة السنوية القديمة بالفدان"])},
  "Comparison Difference in %": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["% نسبة الفرق بين القيمتين"])},
  "Get Comparison Land Area": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مقارنة مساحة الأرض"])},
  "Get Comparison Animals Number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مقارنة عدد الحيوانات"])},
  "Last Year Number of Animals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد الحيوانات السنوي الجديد"])},
  "Second Last Year Number of Animals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد الحيوانات السنوي القديم"])},
  "Comparison Difference in Animals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["%نسبة الفرق بين العددين"])}
}