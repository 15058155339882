import PollutionPerCrop from "../components/agriculture user/PollutionPerCrop.vue";

const EditAgriculture = () => import("../components/admin/agriculture/EditAgriculture.vue");
const EditAnimals = () => import("../components/admin/agriculture/EditAnimals.vue");
const SearchAgriculture = () => import("../components/admin/agriculture/SearchAgriculture.vue");
const SearchAnimals = () => import("../components/admin/agriculture/SearchAnimals.vue");
const SearchAgricultureCategories = () => import("../components/admin/agriculture/SearchAgricultureCategories.vue");
const SearchAnimalsCategories = () => import("../components/admin/agriculture/SearchAnimalsCategories.vue");

const AgricultureUser = () => import("../components/agriculture user/AgricultureUser.vue");
const AgricultureMain = () => import("../components/agriculture user/AgricultureMain.vue");
const Agriculture = () => import("../components/agriculture user/Agriculture.vue");
const NumberOfAnimalsGov = () => import("../components/agriculture user/NumberOfAnimalsGov.vue");

const PollutionPerSectorEdited = () => import("../components/agriculture user/PollutionPerSectorEdited.vue")
const PollutionPerSectorAgri = () => import("../components/agriculture user/PollutionPerSector.vue")
const ComparisonLandArea = () => import("../components/admin/agriculture/ComparisonLandArea.vue")
const ComparisonAnimalsNumber = () => import("../components/admin/agriculture/ComparisonAnimalsNumber.vue")
const AgricultureRequests = () => import("../components/admin/agriculture/AgricultureRequests.vue")
const ChatPage = () => import("../components/admin/ChatPage.vue")
const AgricultureTopFivePollutantPerSector = () => import("../components/reports/AgricultureTopFivePollutantPerSector.vue")
const LandAreaPerGov = () => import("../components/agriculture user/LandAreaPerGov.vue")
const LandAreaPerSector = () => import("../components/agriculture user/LandAreaPerSector.vue")
const AnimalsNumberPerSector = () => import("../components/agriculture user/AnimalsNumberPerSector.vue")
const TopPollutantsPerGov = () => import("../components/agriculture user/TopPollutantsPerGov.vue")
const TopPollutantsPerSector = () => import("../components/agriculture user/TopPollutantsPerSector.vue")

const MapView = () => import("../components/map/MapView.vue")
const AgricultureFactoriesPerSector = () => import("../components/reports/AgricultureFactoriesPerSector.vue")
const ImportPage = () => import("../components/Import.vue");

import { userTypes } from "./userTypes";

export default [{
    name: "AgricultureUser",
    path: "/agricultureUser",
    meta: {
        requiresAuth: true,
        allowedUsers: [
            userTypes.Super,
            userTypes.Admin,
            userTypes.ContentCreator,
            userTypes.DecisionMaker,
            userTypes.AgricultureUser,
        ],
    },
    component: AgricultureMain,
    children: [
        {
            name: "AgricultureUserDashboard",
            path: "dashboard",
            meta: {
                requiresAuth: true,
                allowedUsers: [
                    userTypes.Super,
                    userTypes.DecisionMaker,
                    userTypes.DataVerifier,
                    userTypes.AgricultureUser,
                ],
            },
            component: AgricultureUser,
        },
        {
            name: "Agriculture",
            path: "",
            meta: {
                requiresAuth: true,
                allowedUsers: [
                    userTypes.Super,
                    userTypes.Admin,
                    userTypes.ContentCreator,
                    userTypes.DecisionMaker,
                    userTypes.AgricultureUser,
                ],
            },
            component: Agriculture,
            children: [
                {
                    name: "pollution-per-gov-dashboard",
                    path: "reports/pollution-per-gov",
                    meta: {
                        requiresAuth: true,
                        allowedUsers: [
                            userTypes.Super,
                            userTypes.DecisionMaker,
                            userTypes.DataVerifier,
                            userTypes.AgricultureUser,
                            userTypes.AirUser,
                            userTypes.WaterUser,
                        ],
                    },
                    component: PollutionPerSectorEdited,
                },
                {
                    name: "landArea-per-gov-dashboard",
                    path: "reports/landArea-per-gov",
                    meta: {
                        requiresAuth: true,
                        allowedUsers: [
                            userTypes.Super,
                            userTypes.DecisionMaker,
                            userTypes.DataVerifier,
                            userTypes.AgricultureUser,
                        ],
                    },
                    component: LandAreaPerGov,
                },
                {
                    name: "NumberOfAnimals-per-gov-dashboard",
                    path: "reports/NumberOfAnimals-per-gov",
                    meta: {
                        requiresAuth: true,
                        allowedUsers: [
                            userTypes.Super,
                            userTypes.DecisionMaker,
                            userTypes.DataVerifier,
                            userTypes.AgricultureUser,
                        ],
                    },
                    component: NumberOfAnimalsGov,
                },
                {
                    name: "pollution-per-sector-dashboard",
                    path: "reports/pollution-per-sector",
                    meta: {
                        requiresAuth: true,
                        allowedUsers: [
                            userTypes.Super,
                            userTypes.DecisionMaker,
                            userTypes.DataVerifier,
                            userTypes.AirUser,
                            userTypes.WaterUser,
                            userTypes.AgricultureUser,
                        ],
                    },
                    component: PollutionPerSectorAgri,
                },
                {
                    name: "pollution-per-crop",
                    path: "reports/pollution-per-crop",
                    meta: {
                        requiresAuth: true,
                        allowedUsers: [
                            userTypes.Super,
                            userTypes.DecisionMaker,
                            userTypes.DataVerifier,
                            userTypes.AirUser,
                            userTypes.WaterUser,
                            userTypes.AgricultureUser,
                        ],
                    },
                    component: PollutionPerCrop,
                },
                {
                    name: "Top-pollutants-per-sector",
                    path: "reports/top-pollutants-per-sector",
                    meta: {
                        requiresAuth: true,
                        allowedUsers: [
                            userTypes.Super,
                            userTypes.DecisionMaker,
                            userTypes.DataVerifier,
                            userTypes.AirUser,
                            userTypes.WaterUser,
                            userTypes.AgricultureUser,
                        ],
                    },
                    component: TopPollutantsPerSector,
                },
                {
                    name: "landArea-per-sector-dashboard",
                    path: "reports/landArea-per-sector",
                    meta: {
                        requiresAuth: true,
                        allowedUsers: [
                            userTypes.Super,
                            userTypes.DecisionMaker,
                            userTypes.DataVerifier,
                            userTypes.AgricultureUser,
                        ],
                    },
                    component: LandAreaPerSector,
                },
                {
                    name: "AnimalsNumber-per-sector-dashboard",
                    path: "reports/AnimalsNumber-per-sector",
                    meta: {
                        requiresAuth: true,
                        allowedUsers: [
                            userTypes.Super,
                            userTypes.DecisionMaker,
                            userTypes.DataVerifier,
                            userTypes.AgricultureUser,
                        ],
                    },
                    component: AnimalsNumberPerSector,
                },
                {
                    name: "Agriculture_ImportFile",
                    path: "import/SectorFile",
                    component: ImportPage,
                    meta: {
                        requiresAuth: true,
                        allowedUsers: [
                            userTypes.Super,
                            userTypes.Admin,
                            userTypes.AgricultureUser,
                        ],
                    },
                    props: { type: "SectorAndData", superId: 1, template: "AgricultureSectors.xlsx" },
                },
                {
                    name: "Agriculture_ImportLookup",
                    path: "import/agricultureLookup",
                    component: ImportPage,
                    meta: {
                        requiresAuth: true,
                        allowedUsers: [
                            userTypes.Super,
                            userTypes.Admin,
                            userTypes.AgricultureUser,
                        ],
                    },
                    props: { type: "agricultureFileLookup", superId: 1, template: "AgricultureLookupTables.xlsx" },
                },
                {
                    name: "Agriculture_EditAgriculture",
                    path: "data/editAgriculture",
                    meta: {
                        requiresAuth: true,
                        allowedUsers: [
                            userTypes.Super,
                            userTypes.Admin,
                            userTypes.DataVerifier,
                            userTypes.AgricultureUser,
                        ],
                    },
                    component: EditAgriculture,
                    props: true,
                },
                {
                    name: "Agriculture_SearchAgricultureCategories",
                    path: "data/searchAgricultureCategories",
                    meta: {
                        requiresAuth: true,
                        allowedUsers: [
                            userTypes.Super,
                            userTypes.Admin,
                            userTypes.DataVerifier,
                            userTypes.AgricultureUser,
                        ],
                    },
                    component: SearchAgricultureCategories,
                    props: true,
                },
                {
                    name: "Agriculture_ComparisonLandArea",
                    path: "requests/comparisonLandArea",
                    meta: {
                        requiresAuth: true,
                        allowedUsers: [
                            userTypes.Super,
                            userTypes.Admin,
                            userTypes.DataVerifier,
                            userTypes.AgricultureUser,
                        ],
                    },
                    component: ComparisonLandArea,
                    props: true,
                },
                {
                    name: "Agriculture_ComparisonAnimalsNumber",
                    path: "requests/comparisonAnimalsNumber",
                    meta: {
                        requiresAuth: true,
                        allowedUsers: [
                            userTypes.Super,
                            userTypes.Admin,
                            userTypes.DataVerifier,
                            userTypes.AgricultureUser,
                        ],
                    },
                    component: ComparisonAnimalsNumber,
                    props: true,
                },
                {
                    name: "Agriculture_SearchAnimalsCategories",
                    path: "data/searchAnimalsCategories",
                    meta: {
                        requiresAuth: true,
                        allowedUsers: [
                            userTypes.Super,
                            userTypes.Admin,
                            userTypes.DataVerifier,
                            userTypes.AgricultureUser,
                        ],
                    },
                    component: SearchAnimalsCategories,
                    props: true,
                },
                {
                    name: "Agriculture_SearchAgriculture",
                    path: "data/searchAgriculture",
                    meta: {
                        requiresAuth: true,
                        allowedUsers: [
                            userTypes.Super,
                            userTypes.Admin,
                            userTypes.DataVerifier,
                            userTypes.AgricultureUser,
                        ],
                    },
                    component: SearchAgriculture,
                    props: true,
                },
                {
                    name: "Agriculture_SearchAnimals",
                    path: "data/searchAnimals",
                    meta: {
                        requiresAuth: true,
                        allowedUsers: [
                            userTypes.Super,
                            userTypes.Admin,
                            userTypes.DataVerifier,
                            userTypes.AgricultureUser,
                        ],
                    },
                    component: SearchAnimals,
                    props: true,
                },
                {
                    name: "Agriculture_EditAnimals",
                    path: "data/editAnimals",
                    meta: {
                        requiresAuth: true,
                        allowedUsers: [
                            userTypes.Super,
                            userTypes.Admin,
                            userTypes.DataVerifier,
                            userTypes.AgricultureUser,
                        ],
                    },
                    component: EditAnimals,
                    props: true,
                },
                {
                    name: "Agriculture_MapAllOptions",
                    path: "map/agriculture",
                    meta: {
                        requiresAuth: true,
                        allowedUsers: [
                            userTypes.Super,
                            userTypes.DecisionMaker,
                            userTypes.DataCollector,
                            userTypes.DataVerifier,
                            userTypes.WaterUser,
                            userTypes.SpecializedDecisionMaker,
                            userTypes.AgricultureUser,
                        ],
                    },
                    component: MapView,
                    props: { superSectorName: 'Agriculture' },
                },
                {
                    name: "Agriculture_Requests",
                    path: "requests/Requests",
                    meta: {
                        requiresAuth: true,
                        allowedUsers: [
                            userTypes.Super,
                            userTypes.Admin,
                            userTypes.DataVerifier,
                            userTypes.AgricultureUser,
                        ],
                    },
                    component: AgricultureRequests,
                    props: true,
                },
                {
                    name: "Agriculture_chat",
                    path: "requests/chat",
                    meta: {
                        requiresAuth: true,
                        allowedUsers: [
                            userTypes.Super,
                            userTypes.Admin,
                            userTypes.DataVerifier,
                            userTypes.AgricultureUser,
                        ],
                    },
                    component: ChatPage,
                },
                {
                    name: "Agriculture_factories-per-sector",
                    path: "reports/factories-per-sector/Factories%20Per%20Agricultural%20Sector/1",
                    meta: {
                        requiresAuth: true,
                        allowedUsers: [
                            userTypes.Super,
                            userTypes.Admin,
                            userTypes.DecisionMaker,
                            userTypes.DataVerifier,
                            userTypes.AgricultureUser
                        ],
                    },
                    component: AgricultureFactoriesPerSector,
                    props: true,
                },
                {
                    name: "Agriculture_top-five-pollution-per-sector",
                    path: "reports/top-five-pollution-per-sector/Factories%20Per%20Agricultural%20Sector/1",
                    meta: {
                        requiresAuth: true,
                        allowedUsers: [
                            userTypes.Super,
                            userTypes.DecisionMaker,
                            userTypes.DataVerifier,
                            userTypes.AgricultureUser
                        ],
                    },
                    component: AgricultureTopFivePollutantPerSector,
                    props: true,
                },
                {
                    name: "Top-pollutants-per-gov",
                    path: "reports/Top-pollutant-per-gov",
                    meta: {
                        requiresAuth: true,
                        allowedUsers: [
                            userTypes.Super,
                            userTypes.DecisionMaker,
                            userTypes.DataVerifier,
                            userTypes.AgricultureUser,
                        ],
                    },
                    component: TopPollutantsPerGov,
                },
            ],
        },
    ],
}]